import { getGddNonSubscriptionTimeSlot, getGddSubscriptionTimeSlot } from 'Classes/utils/DlpUtils';
import { WORDINGS } from 'Classes/Wordings';
import { Context, SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';

/**
 * Get the error message for a given GDD (Group Delivery Day).
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {string} - The error message for the GDD.
 */
export const getGddStatus = (gdd, { context }) => {
  if (gdd.isTooLate) return WORDINGS.GDD_TOO_LATE.STATUS;
  if (isGddFull(gdd, { context })) return WORDINGS.GDD_FULL.STATUS;
  if (!gdd.isNextDeliveryAvailable) return WORDINGS.GDD_NOT_AVAILABLE.STATUS;
  if (isGddClosed(gdd, { context })) return WORDINGS.GDD_CLOSED.STATUS;
  return undefined;
};
/**
 * Check if a GDD (Group Delivery Day) is disabled.
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {boolean} - True if the GDD is disabled.
 */
export const isGddDisabled = (gdd, { context }) => {
  return context === Context.Order && (SubscriptionContexts.includes(context)
    ? gdd.isGddDisabledForSubscription
    : gdd.isGddDisabled || (gdd.isTooLate && !gdd.isNextDeliveryAvailable));
};
/**
 * Check if a GDD (Group Delivery Day) is full.
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {boolean} - True if the GDD is full.
 */
export const isGddFull = (gdd, { context }) => {
  return context === Context.Order && (SubscriptionContexts.includes(context)
    ? gdd.isFullForSubscription
    : gdd.isFull);
};
/**
 * Check if a GDD (Group Delivery Day) is closed.
 *
 * @param {Object} gdd - The GDD object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {boolean} - True if the GDD is closed.
 */
export const isGddClosed = (gdd, { context }) => {
  return (SubscriptionContexts.includes(context)
    ? getGddSubscriptionTimeSlot(gdd) === null
    : getGddNonSubscriptionTimeSlot(gdd) === null);
};
/**
 * Check if a delivery point (DLP) is disabled.
 *
 * @param {Object} deliveryPoint - The delivery point object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {boolean} - True if the delivery point is disabled.
 */
export const isDlpDisabled = (deliveryPoint, { context }) => {
  const hasGddAvailable = deliveryPoint?.groupDeliveryDay.some(gdd => !isGddDisabled(gdd, { context }));
  const isDisabled = SubscriptionContexts.includes(context)
    ? deliveryPoint.isDisabledForSubscription
    : deliveryPoint.isDisabled;
  return !hasGddAvailable || isDisabled;
};
/**
 * Check if a delivery point (DLP) is full.
 *
 * @param {Object} deliveryPoint - The delivery point object.
 * @param {Object} options - Options object.
 * @param {string} options.context - The context in which the function is called.
 * @returns {boolean} - True if the delivery point is full.
 */
export const isDlpFull = (deliveryPoint, { context }) => {
  return (deliveryPoint.groupDeliveryDay ? !deliveryPoint.groupDeliveryDay
    .filter((gdd) => !isGddFull(gdd, { context })).length : false);
};
