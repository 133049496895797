import { CANCEL_ORDER_ACTION } from 'Stores/types/userActionsTypes';
import Survey from 'Classes/surveys';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';

import { notify } from '@kyvg/vue3-notification';
import { getRouteParams } from 'Classes/utils/RouteUtils';

const REPLACE_BUTTON = (orderId) => {
  return {
    redirect: {
      name: 'mon-compte_orders_detail_replace',
      params: { orderId },
    },
    btnLabel: 'Remplacer mon panier',
  };
};

const QUESTIONS = (orderId) => ({
  1: {
    title: 'Êtes-vous sûr de vouloir annuler votre commande ?',
    desc: 'Souhaitez-vous nous dire ce qui vous pousse à annuler cette commande ? Votre réponse n’est pas obligatoire, elle nous aidera surtout à progresser 🙂',
    type: 'radio',
    choices: [
      {
        label: 'La composition de mon panier de la semaine ne me convient pas.',
        nextQuestion: 2,
      },
      {
        label: 'Il me reste assez de fruits et légumes.',
        nextQuestion: 3,
      },
      {
        label: 'Impossible de récupérer mon panier le jour\u00a0J.',
        didyouknow: {
          text: 'Votre point relais conservera votre commande 24h après sa livraison (selon horaires d’ouverture). Vous avez aussi la possibilité de changer la date de votre livraison depuis votre espace client.',
        },
      },
      {
        label: 'Je suis absent quelques jours.',
        didyouknow: {
          text: 'Vous pouvez remplacer votre panier actuel par un autre au format plus petit ou alors changer votre jour de livraison.',
        },
      },
      {
        label: 'Autres',
        comment: {
          type: 'input',
        },
      },
    ],
  },
  2: {
    title: 'Quels fruits et légumes ne vous conviennent pas ?',
    type: 'checkbox',
    didyouknow: {
      text: 'Vous pouvez remplacer votre panier actuel parmi ceux présents dans notre boutique.',
      ...REPLACE_BUTTON(orderId)
    },
    choices: [/* Voir onInit() */],
  },
  3: {
    title: 'Pourquoi vous reste-il assez de fruits et légumes ?',
    type: 'radio',
    choices: [
      {
        label: 'J\'avais trop de produits dans mon précédent panier.',
        didyouknow: {
          text: 'Vous pouvez remplacer votre panier actuel par un autre au format plus petit pour votre consommation de la semaine.',
          ...REPLACE_BUTTON(orderId)
        },
      },
      {
        label: 'J\'ai fait mes courses ailleurs.',
      },
    ],
  },
});

const CONFIG = {
  onInit: (ctx) => new Promise((resolve) => {
    const {
      store,
      router
    } = ctx;
    const orderId = getRouteParams(router.currentRoute)?.orderId;
    const order = store.getters['user/getOrderById'](orderId);
    const questions = QUESTIONS(orderId);

    questions[2].choices = uniqBy(flatten(order.boxes
      .map((box) => box.products
        .map((product) => ({
          label: product.name,
          productId: product.id,
        })))), 'productId');

    resolve({ questions });
  }),
  onSubmit: (ctx, { orderId }) => {
    const {
      store,
      router,
      savedChoices
    } = ctx;

    let label = '';
    let subReason = '';
    let comment = '';
    const products = [];

    if (savedChoices?.length) {
      savedChoices
        .forEach((e) => {
          e.choices.forEach((answer) => {
            if (answer.productId) {
              products.push(answer.productId);
            } else {
              if (answer.label) {
                if (label.length) {
                  subReason += `${subReason.length ? ' ' : ''}${answer.label}`;
                } else {
                  label = answer.label;
                }
              }
              if (answer.comment) comment += `${comment.length ? ' ' : ''}${answer.comment}`;
            }
          });
        });
    }

    store.dispatch(`user/${CANCEL_ORDER_ACTION}`, {
      orderId,
      label,
      subReason,
      comment,
      products,
    })
      .then(() => {
        notify({
          title: 'Commande annulée',
          text: 'Votre commande a bien été annulée.',
          type: 'success',
        });

        router.push({
          name: 'mon-compte_orders_detail',
          params: { orderId },
        });
      })
      .catch(() => {
        notify({
          title: 'Oops ! Il y a un pépin...',
          text: 'Une erreur est survenue lors de l\'annulation de votre commande. Merci de réessayer plus tard ou de contacter <a href="https://www.potagercity.fr/contact" class="underline">notre service client</a> si le problème persiste.',
          type: 'error',
        });
      });
  },
};

export default (app) => {
  const orderId = getRouteParams(app.$potagerRoute)?.orderId;
  const questions = QUESTIONS(orderId);
  return new Survey(questions, CONFIG, app);
}
