<template>
  <div
    :class="{
      'payment-card-wrapper': true,
      'payment-card-wrapper--standalone': standalone,
    }">
    <component
      :is="radio ? 'potager-radio' : 'div'"
      :id="card.id"
      :disabled="card.isExpired || isLoading"
      :model-value="isSelected"
      class="w-full"
      group-name="cards"
      @input="$emit('onChange', card.id)">
      <div
        :class="{
          'payment-card': true,
          'payment-card--primary': card.priority === PRIMARY && !standalone && !radio,
          'payment-card--selected': isSelected,
          'payment-card--radio': radio,
        }">
        <div class="payment-card__content">
          <div class="absolute top-2 left-2 flex gap-2">
            <potager-badge
              v-if="isDefault"
              key="default"
              size="small"
              theme="charcoal">
              {{
                card.priority || card.is_primary_payment_method ? 'Carte par défaut' : 'Carte complémentaire par défaut'
              }}
            </potager-badge>

            <potager-badge
              v-if="!card.isExpired"
              key="valid"
              size="small"
              theme="wild-willow">
              Valide
            </potager-badge>

            <potager-badge
              v-else
              key="expired"
              size="small"
              theme="zest">
              Expiré
            </potager-badge>
          </div>

          <component
            :is="getCardIcon(card, true)"
            v-if="card.brand"
            :style="{
              'color': paymentMethod.colors?.tertiary,
            }"
            class="payment-card__brand" />

          <p class="payment-card__number">
            {{ getNumber }}
          </p>

          <div
            v-if="card.holderName"
            class="payment-card__holder-name">
            <p class="payment-card__legend">
              Titulaire de la carte
            </p>
            {{ card.holderName }}
          </div>

          <div class="payment-card__expiry ml-auto">
            <p class="payment-card__legend">
              Expiration
            </p>
            {{ expiration }}
          </div>
        </div>
      </div>
    </component>

    <p
      v-if="actions"
      class="payment-card__actions flex gap-2">
      <potager-button
        v-if="!isDefault || (!card.priority && !card.is_primary_payment_method)"
        :is-disabled="!canSetAsDefault"
        :is-loading="isLoading"
        class="payment-card__actions__btn"
        disabled-message="Vous ne pouvez pas définir cette carte comme carte par défaut"
        size="small"
        theme="white"
        @onClick="changeDefaultCard">
        {{ isDefault ? 'Ne plus définir par défaut' : 'Définir par défaut' }}
      </potager-button>

      <potager-button
        :is-loading="isLoading"
        class="payment-card__actions__btn"
        size="small"
        theme="white"
        @onClick="onRemoveCard">
        Supprimer
      </potager-button>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  REMOVE_CARD_ACTION,
  SET_DEFAULT_CARD_ACTION,
  PAY_BASKET_BY_PAYMENT_METHODS_ACTION,
} from 'Stores/types/userActionsTypes';

import PotagerButton from 'UI/PotagerButton';
import PotagerBadge from 'UI/PotagerBadge';
import PotagerRadio from 'UI/Form/PotagerRadio';

import { getCardIcon, getPaymentMethod } from 'Classes/payment-methods';
import { PRIMARY, SECOND } from 'Classes/Constants';

export default {

  components: {
    PotagerButton,
    PotagerBadge,
    PotagerRadio,
  },
  emits: ['onRemoveCard', 'onChangeDefaultCard', 'onChange'],

  data: () => ({
    PRIMARY,
    SECOND,
    REMOVE_CARD_ACTION,
    SET_DEFAULT_CARD_ACTION,
  }),

  props: {
    card: {
      type: Object,
      required: true,
    },
    standalone: {
      type: Boolean,
      required: false,
      default: false,
    },
    actions: {
      type: Boolean,
      required: false,
      default: false,
    },
    radio: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getBankCards',
      'getSubscription',
      'getBankCards',
    ]),
    expiration() {
      let { expirationDate } = this.card;
      expirationDate = expirationDate.replace(/\D/g, '');
      return `${expirationDate.slice(0, 2)}/${expirationDate.slice(-2)}`;
    },
    getNumber() {
      return this.card.number.replace(/XXXX/g, '•••• ');
    },
    canSetAsDefault() {
      return !this.card.isExpired;
    },
    isLoading() {
      return this.$wait.is(`${SET_DEFAULT_CARD_ACTION}_${this.card.id}`)
        || this.$wait.is(`${REMOVE_CARD_ACTION}_${this.card.id}`)
        || this.$wait.is([PAY_BASKET_BY_PAYMENT_METHODS_ACTION]);
    },
    isDefault() {
      // priority is used for STRIPE cards
      // priority === PRIMARY -> default card

      // usableForSubscription && isActiveForSubscription is used for PAYGREEN cards
      // > isActiveForSubscription is used for paygreen BANK cards -> default card
      // > usableForSubscription is used for paygreen RESTAURANTS cards -> default card
      return (this.card.priority === PRIMARY || this.card.usableForSubscription || this.card.isActiveForSubscription) && !this.standalone && !this.radio;
    },
    paymentMethod() {
      return getPaymentMethod(this.card);
    },
  },

  methods: {
    getCardIcon,
    async onRemoveCard() {
      this.$emit('onRemoveCard', this.card.id);

      if (this.isDefault && this.getSubscription.status === true) {
        // dynamic import to avoid circular dependency
        const ModalDeletePrimaryCard = await import('Modals/ModalDeletePrimaryCard');
        await this.$modal.open(ModalDeletePrimaryCard.default, {
          blueCards: this.getBankCards,
          primaryBlueCard: this.card,
          action: this.removeCard,
        });
      } else {
        await this.removeCard();
      }
    },
    removeCard() {
      const cardId = this.card.id;

      return new Promise((resolve, reject) => {
        this.$store.dispatch(`user/${REMOVE_CARD_ACTION}`, cardId)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Carte supprimée',
              text: 'Votre carte a bien été supprimée',
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeDefaultCard() {
      if (this.canSetAsDefault) {
        const cardId = this.card.id;
        this.$emit('onChangeDefaultCard', cardId);
        this.$store.dispatch(`user/${SET_DEFAULT_CARD_ACTION}`, {
          cardId,
          isDefault: !this.isDefault
        })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Carte par défaut modifiée',
              text: 'Votre carte par défaut a bien été modifiée',
            });
          });
      }
    },
  },

};
</script>
