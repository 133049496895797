export const WORDINGS = {
  DLP_FULL: 'Vous ne pouvez pas sélectionner ce point de retrait car il est complet. Veuillez en sélectionner un autre avant de valider votre abonnement.',
  DLP_NOT_IN_SAME_REGION: 'Vous ne pouvez pas choisir ce point car il est en dehors de votre région. Contactez-le service client si vous souhaitez changer de région.',
  GDD_CLOSED: {
    STATUS: 'Ce point de retrait est actuellement fermé 😕',
  },
  GDD_DELETE: {
    DEFAULT: 'Le jour de retrait sélectionné n’est plus disponible.',
    CONTEXT_BASKET: `Le jour de retrait sélectionné de votre panier n’est plus disponible. <br><br>{minimumDateBrief}`,
  },
  GDD_FULL: {
    DEFAULT: `Vous ne pouvez pas choisir ce jour de retrait car il est actuellement complet. Veuillez sélectionner un autre jour et/ou un autre point de retrait.`,
    CONTEXT_BASKET: `Vous ne pouvez pas ajouter d'élément à votre panier car le point de retrait est complet sur le jour sélectionné et ne peut plus accueillir de nouvelles commandes. <br><br>{minimumDateBrief}`,
    STATUS: 'Ce point de retrait est complet 😕',
  },
  GDD_NOT_AVAILABLE: {
    DEFAULT: 'Vous ne pouvez pas sélectionner ce jour de retrait car il est indisponible.',
    STATUS: 'Le prochain jour de retrait n\'est pas disponible 😕',
  },
  GDD_TOO_LATE: {
    DEFAULT: 'Votre jour de retrait est fixé pour la prochaine sélection de produits. Veuillez choisir un autre jour de retrait ou ajouter de nouveaux articles à votre panier à partir de jeudi.',
    STATUS: 'Trop tard pour choisir ce jour 😕\nRevenez à partir de jeudi.',
  },
  GROUP_CODES_WARNING: `Vous accédez à la recherche d’un point de retrait privé dont l’entreprise a un partenariat avec Potager City. Si vous choisissez un point de retrait privé, vous devez être autorisé(e) à vous rendre dans l'établissement pour récupérer vos commandes.`,
  MENTION_CATEGORY: `Chez Potager City, nous avons fait le choix de ne pas acheter de fruits et légumes calibrés (sauf cas réglementaires obligatoires). Nos produits sont de Catégorie II.`,
  MENTION_WEIGHT: `Les poids ou nombres de pièces sont des estimations. Ils peuvent légèrement varier en fonction de la taille des produits récoltés.`,
  MINIMUM_DATE_BRIEF: 'Si vous souhaitez commander un panier cette semaine et être livré avant le {minimumDate}, vous devez <b>modifier votre date de retrait ou votre point de retrait.</b>',
  BOX_LOCKED: {
    STATUS: 'Non disponible à la vente pour le moment',
  },
};
