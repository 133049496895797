<template>
  <subscriber-bar-item
    v-if="isOrderCanceledStatus(getSubscriptionOrder)"
    :image="getSubscriptionOrder?.product?.image"
    :redirect="routes.mySubscriptionOrder"
    text="Vous avez annulé votre panier">
    <template #icon>
      <icon-road-sign-banned />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="getSubscriptionOrder && !getSubscriptionOrder?.containsSubscriptionBox"
    :image="getSubscriptionOrder?.product?.image"
    :redirect="routes.mySubscriptionOrder"
    text="Vous avez choisi un autre panier">
    <template #icon>
      <icon-check-circle1 />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="getSubscription?.temporaryOrder || isNotYetCreated"
    :redirect="routes.mySubscription"
    text="Rendez-vous jeudi pour découvrir la prochaine composition">
    <template #icon>
      <icon-stopwatch />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="!getNextGenerationDate && !getSubscriptionOrder"
    :redirect="routes.mySubscription"
    is-warning
    text="Votre point de retrait est indisponible">
    <template #icon>
      <icon-road-sign-warning />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else
    :image="getSubscription?.product?.image"
    :redirect="routes.subscriptionPage"
    text="Voir la composition du panier" />
</template>

<script>
import IconRoadSignBanned from 'Icons/regular/IconRoadSignBanned';
import IconCheckCircle1 from 'Icons/regular/IconCheckCircle1';
import IconStopwatch from 'Icons/regular/IconStopwatch';
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';

import SubscriptionMixin from 'Mixins/SubscriptionMixin';
import SubscriberBarItem from 'Components/subscriberBar/SubscriberBarItem';
import { isOrderCanceledStatus } from 'potagerlogic/dist/Utils/Order/OrderStatus';

export default {
  methods: { isOrderCanceledStatus },
  mixins: [
    SubscriptionMixin,
  ],
  components: {
    IconRoadSignBanned,
    IconCheckCircle1,
    IconStopwatch,
    IconRoadSignWarning,
    SubscriberBarItem,
  },

  props: {
    routes: {
      type: Object,
      required: true,
    },
  },
};
</script>
