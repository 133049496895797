<template>
  <card-layout
    v-if="!box || box.loading || skeleton"
    :landscape="landscape">
    <card-picture
      :landscape="landscape"
      skeleton />

    <card-body :landscape="landscape">
      <card-locality skeleton />
      <card-title skeleton />

      <template
        v-if="!readOnly"
        #footer>
        <card-box-price skeleton />
        <card-box-tools
          class="absolute bottom-0 right-0"
          skeleton />
      </template>
    </card-body>
  </card-layout>

  <card-layout
    v-else
    :box="box"
    :landscape="landscape"
    :redirect="readOnly ? undefined : redirect">
    <card-picture
      :extra-img-class="extraImgClass"
      :landscape="landscape"
      :no-lazy-load="noLazyLoad"
      :src="isTemporaryOrder ? 'placeholder-temporary-order' : box.image">
      <card-box-overlay
        v-if="!replace && !readOnly && canEditQuantity"
        :box="box"
        :is-loading="isLoading"
        :quantity="nbItems">
        <template v-if="!isOrderMode">
          dans mon panier
        </template>
      </card-box-overlay>

      <card-box-tags
        v-if="!readOnly && tags && tags.length"
        :tags="tags" />

      <card-box-labels
        v-if="box?.labels?.length"
        :labels="box.labels" />
    </card-picture>

    <card-body :landscape="landscape">
      <card-locality
        v-if="box"
        :box="box"
        :small="small" />

      <card-title
        :title="getSupplierName ? box.name + ' de ' + getSupplierName : box.name"
        class="font-semibold">
        {{ box.name }}

        <span
          v-if="getSupplierName"
          class="font-normal">
          de {{ getSupplierName }}
        </span>
      </card-title>

      <card-box-composition
        v-if="isFormule && !isTemporaryOrder && !landscape && !readOnly"
        :products="box.products" />

      <template
        v-if="!readOnly"
        #footer>
        <card-box-price
          :box="box"
          :order="order"
          :show-sub-price="showSubPrice" />

        <card-box-tools
          v-if="!readOnly && !replace"
          :box="box"
          :can-edit-quantity="canEditQuantity"
          :disabled="box.isAssociableToVouchers"
          :is-loading="isLoading"
          :quantity="nbItems"
          class="absolute bottom-0 right-0"
          @decrement="decrement"
          @edit="edit"
          @increment="increment" />

        <div
          v-if="$slots.footer"
          class="pt-1.5">
          <slot name="footer" />
        </div>
      </template>
    </card-body>

    <slot name="layout-after" />
  </card-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import BasketActionMixin from 'Mixins/BasketActionMixin';

import { ABONNEMENT } from 'Classes/OrderStatus';
import { calcSlug } from 'Classes/utils/RouteUtils';
import { getBoxSuppliers } from 'Classes/utils/BoxesUtils';
import { getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';

import CardBody from 'Components/cards/UI/CardBody';
import CardBoxComposition from 'Components/cards/UI/CardBoxComposition';
import CardBoxLabels from 'Components/cards/UI/CardBoxLabels';
import CardBoxOverlay from 'Components/cards/UI/CardBoxOverlay';
import CardBoxPrice from 'Components/cards/UI/CardBoxPrice';
import CardBoxTags from 'Components/cards/UI/CardBoxTags';
import CardBoxTools from 'Components/cards/UI/CardBoxTools';
import CardLayout from 'Components/cards/UI/CardLayout';
import CardLocality from 'Components/cards/UI/CardLocality';
import CardPicture from 'Components/cards/UI/CardPicture';
import CardTitle from 'Components/cards/UI/CardTitle';

export const props = {
  box: {
    type: Object,
    required: false,
    default: null,
  },
  landscape: {
    type: Boolean,
    required: false,
    default: false,
  },
  order: {
    type: Object,
    required: false,
    default: null,
  },
  isTemporaryOrder: {
    type: Boolean,
    required: false,
    default: false,
  },
  replace: {
    type: Boolean,
    required: false,
    default: false,
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  noLazyLoad: {
    type: Boolean,
    required: false,
    default: false,
  },
  extraImgClass: {
    type: String,
    required: false,
    default: '',
  },
  overlay: {
    type: Boolean,
    required: false,
    default: false,
  },
  skeleton: {
    type: Boolean,
    required: false,
    default: false,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  mixins: [
    BasketActionMixin,
  ],

  components: {
    CardBody,
    CardBoxComposition,
    CardBoxLabels,
    CardBoxOverlay,
    CardBoxPrice,
    CardBoxTags,
    CardBoxTools,
    CardLayout,
    CardLocality,
    CardPicture,
    CardTitle,
  },

  props,

  computed: {
    ...mapGetters('basket', [
      'getDeliveryPoint',
      'getProducts',
      'isSubscription',
    ]),
    ...mapGetters('user', [
      'getBasketLimit',
    ]),
    redirect() {
      if (!this.isTemporaryOrder) {
        let name = 'detail-product-item';
        if (this.replace) name = 'replace-detail-product-item';
        return {
          name,
          params: {
            slug: calcSlug(this.box.name),
            id: this.box.id,
            idRegion: this.box.region?.id,
            orderId: this.order?.id,
          },
        };
      }
      return null;
    },
    showSubPrice() {
      if (!!this.order) return this.order.canal === ABONNEMENT || this.isTemporaryOrder;
      return this.isSubscription;
    },
    nbItems() {
      if (this.isTemporaryOrder) return null;
      const boxes = !this.order ? this.getProducts : this.order.boxes;
      if (boxes) return boxes.filter((box) => box.id === this.box.id)?.length;
      return null;
    },
    isLoading() {
      const { id } = this.box;
      return this.$wait.is([
        `ADD_BOX_BASKET_ACTION_${id}`,
        `DELETE_BOX_BASKET_ACTION_${id}`,
        `EDIT_BOX_QUANTITY_BASKET_ACTION_${id}`,
      ]);
    },
    isFormule() {
      return this.box.isTypeFormule;
    },
    canEditQuantity() {
      // return !this.order && this.getBasketLimit > 1;
      return !this.order;
    },
    getSupplierName() {
      return this.box.isComplementary ? getBoxSuppliers(this.box) : null;
    },
    tags() {
      return this.box.tags?.filter((tag) => tag.type !== 'primary');
    },
  },

  methods: {
    increment() {
      if (this.getDeliveryPoint) {
        this.addBox(this.box);
      } else {
        this.$router.push(getDlpSearchRoute());
      }
    },
    decrement() {
      this.removeBox(this.box);
    },
    edit(newQuantity) {
      if (this.canEditQuantity) {
        this.editBoxQuantity(this.box, newQuantity);
      }
    },
  },

};
</script>
