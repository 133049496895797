import Stripe from 'Classes/payment-methods/Stripe';
import Edenred from 'Classes/payment-methods/Edenred';
import Swile from 'Classes/payment-methods/Swile';
import Up from 'Classes/payment-methods/Up';
import Pluxee from 'Classes/payment-methods/Pluxee';
import Bimpli from 'Classes/payment-methods/Bimpli';
import PaygreenBankCard from 'Classes/payment-methods/PaygreenBankCard';

import IconPtcyVisa from 'Icons/color/IconPtcyVisa';
import IconPtcyMastercard from 'Icons/color/IconPtcyMastercard';
import IconPtcyAmex from 'Icons/color/IconPtcyAmex';

import { PAYMENT_METHODS_TYPES } from 'Classes/payment-methods/Constants';

export const PRIMARY_PAYMENT_METHODS = [
  Stripe,
  PaygreenBankCard,
];

export const LOGIN_PAYMENT_METHODS = [
  Edenred,
  Swile,
];

export const PAYGREEN_PAYMENT_METHODS = [
  Bimpli,
  Pluxee,
  Up,
  PaygreenBankCard,
];

// trier dans l'ordre alphabétique de l'attribut name
export const SECONDARY_PAYMENT_METHODS = [
  ...LOGIN_PAYMENT_METHODS,
  Bimpli,
  Pluxee,
  Up,
].sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

export const PAYMENT_METHODS = [
  ...PRIMARY_PAYMENT_METHODS,
  ...SECONDARY_PAYMENT_METHODS,
];

/**
 * Retrieves payment methods by their type.
 *
 * @param {string} type - The type of payment methods to filter by.
 * @returns {Array} - An array of payment methods matching the specified type.
 */
export const getPMsByType = (type) => PAYMENT_METHODS?.filter((e) => e.type === type);
/**
 * Retrieves a payment method by its name, alias, or issuer.
 *
 * @param {string} name - The name, alias, or issuer of the payment method.
 * @returns {Object|null} - The matching payment method object or null if not found.
 */
export const getPMByName = (name) =>
  PAYMENT_METHODS?.find((e) => e.name === name || e.alias === name || e.issuer === name);

/**
 * Retrieves the payment method based on the provided payment method object.
 *
 * @param {Object} PM - The payment method object containing details about the payment method.
 * @param {string} PM.brand - The brand of the payment method (e.g., 'visa', 'mastercard', 'amex').
 * @param {string} PM.issuer - The issuer of the payment method.
 * @param {string} PM.name - The name of the payment method.
 * @returns {Object|null} - The matching payment method object or null if not found.
 */
export const getPaymentMethod = (PM) => {
  if (!PM) return null;
  const brand = PM.brand?.toLowerCase();

  const stripeBrands = ['visa', 'mastercard', 'amex'];
  const paygreenBrands = ['bank_card'];

  const matchingBrand = stripeBrands.includes(brand)
    ? Stripe
    : paygreenBrands.includes(brand)
      ? PaygreenBankCard
      : null;
  if (matchingBrand) return matchingBrand;

  return PAYMENT_METHODS?.find(({
    issuer,
    name
  }) => issuer === PM.issuer || name === PM.name);
};

/**
 * Retrieves the icon for a given card.
 *
 * @param {Object} card - The card object containing details about the card.
 * @param {boolean} [alternative=false] - Flag to determine if the alternative icon should be returned.
 * @returns {Object|null} - The icon object for the card or null if not found.
 */
export const getCardIcon = (card, alternative = false) => {
  const paymentMethod = getPaymentMethod(card);
  if (paymentMethod.type === PAYMENT_METHODS_TYPES.BANK_CARD) {
    return {
      visa: IconPtcyVisa,
      mastercard: IconPtcyMastercard,
      amex: IconPtcyAmex,
    }[card.brand] || null;
  }

  return alternative ? paymentMethod.alternativeIcons[0] : paymentMethod.defaultIcon;
};
