import { createApp } from 'vue';

import 'intersection-observer';

import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/vue';

import 'Classes/HeadScripts';

import router from 'Router';
import store from 'Stores';
import { createGtm } from '@gtm-support/vue-gtm';

// Directives
import VWave from 'v-wave';
import StickyBottom from 'Directives/StickyBottom';
import GTM from 'Directives/GTM';
import IntersectionObserver from 'Directives/IntersectionObserver';

// plugins
import PotagerRouting from 'Plugins/potagerRouting';
import PotagerModals from 'Plugins/potagerModals';
import PotagerApiClient from 'Plugins/potagerApiClient';
import PotagerEvents from 'Plugins/potagerEvents';
import PotagerMediaQueries from 'Plugins/potagerMediaQueries';
import PotagerGeolocation from 'Plugins/potagerGeolocation';
import PotagerWait from 'Plugins/potagerWait';

import { createHead, VueHeadMixin } from '@unhead/vue';
import VueSocials from 'vue-socials';
import Notifications from '@kyvg/vue3-notification';
import VueScrollTo from 'vue-scrollto';

import { SET_IS_READY } from 'Stores/types/appMutationsTypes';

import App from 'Root/App';

import interceptorSetup from 'Classes/Interceptors';

import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'tocca';

import packageJson from '../package.json';

setDefaultOptions({ locale: fr });
window.tocca({ swipeThreshold: 75 }); // used to add touch events

export const app = createApp(App);

const gtm = createGtm({
  id: import.meta.env.VITE_GOOGLE_GTM_ID,
  enabled: true,
  debug: import.meta.env.VITE_WEB_ENV === 'development',
  vueRouter: router,
  vueRouterAdditionalEventData: () => {
    const isLoggedIn = store.getters['session/isLoggedIn'];
    const isSubscriptionActive = store.getters['user/isSubscriptionActive'];
    const userid = store.getters['user/getUserId'] ? store.getters['user/getUserId'] : null;
    const regionID = store.getters['user/getRegionIdForGTM'];
    return {
      isLoggedIn,
      isSubscriptionActive,
      userid,
      regionID
    };
  },
  trackOnNextTick: true,
});

app
  .use(gtm)
  .use(router)
  .use(PotagerRouting, {
    router,
    store,
  })
  .use(store)
  .use(PotagerWait, store)
  .use(PotagerModals)
  .use(PotagerApiClient)
  .use(PotagerEvents)
  .use(PotagerMediaQueries)
  .use(PotagerGeolocation)
  .use(VWave)
  .use(createHead())
  .use(VueSocials)
  .use(Notifications)
  .use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  })
  .directive('sticky-bottom', StickyBottom)
  .directive('gtm', GTM)
  .directive('intersect', IntersectionObserver)
  .mixin(VueHeadMixin);

if (import.meta.env.VITE_WEB_ENV === 'development') {
  app.config.errorHandler = undefined;
  app.config.warnHandler = undefined;
  app.config.performance = true;
}

firebase.initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
});

const isPreprod = window.location.hostname.includes('preprod');
if (import.meta.env.VITE_WEB_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        enableInp: true,
      }),
      Sentry.replayIntegration({
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.feedbackIntegration({
        colorScheme: 'light',
        showBranding: false,
        isEmailRequired: true,
        triggerLabel: 'Signaler un bug',
        formTitle: '📣 Dites nous tout !',
        submitButtonLabel: 'Envoyer',
        cancelButtonLabel: 'Annuler',
        confirmButtonLabel: 'Envoyer',
        addScreenshotButtonLabel: 'Ajouter une capture d\'écran',
        removeScreenshotButtonLabel: 'Supprimer la capture d\'écran',
        nameLabel: 'Nom',
        namePlaceholder: 'Votre nom',
        emailLabel: 'Email',
        emailPlaceholder: 'Votre email',
        isRequiredLabel: '(Obligatoire)',
        messageLabel: 'Description du problème',
        messagePlaceholder: 'Merci de détailler au maximum le bug rencontré ou la suggestion d\'amélioration. Plus vous détaillez, mieux nous pourrons vous aider !',
        successMessageText: 'Merci pour votre retour ! PotagerCity va étudier votre signalement. 📝 Si le problème persiste, n\'hésitez pas à contacter notre service client pour une assistance plus approfondie.',
      }),
    ],
    debug: import.meta.env.VITE_WEB_ENV === 'development',
    ignoreErrors: [
      // OneTrust errors
      'undefined is not an object (evaluating \'t.Domain\')',

      // Avoid repetitive non-critical errors
      /^.*Loading chunk \[\d+\] failed.*$/,
      /^.*NetworkError.*$/,
      /^Network Error$/,
      'Request failed with status code 401',

      // CORS errors
      'Blocked a frame with origin',
      'Blocked by CORS policy',

      // Network-related errors
      'Failed to load resource: net::ERR_CONNECTION_RESET',
      'Failed to load resource: net::ERR_NETWORK_CHANGED',
      'Failed to load resource: net::ERR_INTERNET_DISCONNECTED',
      'net::ERR_FAILED',

      // Timeout errors
      'TimeoutError',

      // Iframe or external content errors
      'Refused to display',
      'X-Frame-Options',
      'Content Security Policy',

      // Legacy browser errors
      'NoSuchMethodError',
      'TypeError: undefined is not a function',

      // LocalStorage and cookie-related errors
      'Uncaught DOMException: Failed to read the \'localStorage\'',
      'QuotaExceededError: Failed to execute \'setItem\' on \'Storage\'',

      // Extension errors
      'chrome-extension://',
      'moz-extension://',

      // Additional errors to ignore
      'ResizeObserver loop limit exceeded',
      'Script error.',
      'AbortError',
      'SecurityError',
      'SyntaxError',
      'ReferenceError',
      'EvalError',
      'RangeError',
      'URIError',
    ],
    tracesSampleRate: isPreprod ? 1 : 0.1,
    trackComponents: false, // need https://potagercity.sentry.io/performance/ (not free)
    replaysSessionSampleRate: isPreprod ? 1 : 0.1,
    replaysOnErrorSampleRate: 1,
    release: packageJson.version,
  });
}

interceptorSetup(app);

app.mount('#app');
store.commit(`app/${SET_IS_READY}`, true);

export default app;
