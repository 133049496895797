<template>
  <panel
    :back-route="backRoute"
    :is-loading="!order"
    :title="title">
    <div v-if="order">
      <account-breadcrumb-orders
        :order="order"
        class="mb-6" />

      <order-indication
        :front-status="order.frontStatus"
        :order="order"
        class="mb-6"
        @resume="onResume(order)" />

      <payment-order-action
        :order="order"
        class="mb-6" />

      <announcement-card
        v-if="order.canal === ABONNEMENT && order.frontStatus === VALIDATED && order.isEditable"
        class="-mt-2"
        theme="craft">
        <template #title>
          Envie de plus ?
        </template>

        <template #text>
          <span v-html="wantMoreText" />
        </template>

        <template #more-content>
          <potager-button
            :is-loading="$wait.is([
              EMPTY_BASKET_ACTION,
              UPDATE_DELIVERY_POINT_BASKET_ACTION
            ])"
            full-width
            theme="wild-willow"
            @onClick="migrateBasketToOrderDlp(order)">
            Compléter ma commande
          </potager-button>
        </template>
      </announcement-card>

      <account-orders-substitute-section
        v-if="order.timeSlot.hasSubstitute"
        :order="order" />

      <div
        v-else
        class="panel-section--transparent p-0">
        <potager-link
          v-if="!isTemporary && order.id"
          :to="{
            name: 'mon-compte_orders_gazette',
            params: {
              status: isHistoricalOrder ? 'livrees' : 'en-cours',
              orderId: order.id,
            }
          }"
          class="leading-0 block">
          <potager-picture
            v-wave
            class="rounded overflow-hidden"
            src="gazette-banner" />
        </potager-link>
      </div>

      <ul class="panel-section">
        <li
          v-for="(box, j) in getBoxesGrouped"
          :key="j"
          class="panel-section__line">
          <card-product
            :box="box"
            :is-temporary-order="isTemporary"
            :order="order"
            :small="!box.isTypeFormule"
            landscape>
            <template #layout-after>
              <div
                v-if="order.isEditable"
                class="flex gap-2 mt-2 w-full">
                <potager-button
                  v-if="order.isSubscriptionOrder && order?.containsSubscriptionBox && box.isTypeFormule"
                  :to="{
                    name: 'mon-compte_orders_detail_replace',
                    params: { orderId: order.id },
                  }"
                  full-width>
                  Remplacer mon panier
                </potager-button>

                <potager-button
                  full-width
                  prevent
                  theme="stroke"
                  @onClick="tryToDeleteProductFromOrder(box, order)">
                  Supprimer
                </potager-button>
              </div>
            </template>
          </card-product>
        </li>
      </ul>

      <account-orders-receipt-detail :order="order" />

      <account-orders-payment-summary :order="order" />

      <dlp-resume
        :context="Context.Order"
        :order="order"
        :readonly="!order.isEditable"
        class="panel-section">
        <p class="panel-section__subtitle">
          {{ order.deliveryPoint.isHomeDelivery ? 'Livraison' : 'Retrait' }}
        </p>
      </dlp-resume>

      <div
        v-if="order?.isCancellable && !isTemporary && !isHistoricalOrder"
        class="panel-section--transparent">
        <potager-button
          full-width
          theme="stroke"
          @onClick="deleteOrder(order)">
          Annuler cette commande
        </potager-button>
        <potager-info
          v-if="(order.frontStatus == PAYED || order.frontStatus === VALIDATED) && order.canal === ONESHOT"
          size="small"
          status="legend">
          Si vous supprimez votre commande,
          nous vous génèrerons un bon d'achat sous forme de coupon.
        </potager-info>
      </div>

      <div
        v-else-if="isTemporary && !isHistoricalOrder"
        class="panel-section">
        <p class="panel-section__p panel-section__p--without-mb">
          Dès jeudi, vous pourrez contrôler entièrement
          votre commande : gérer vos coupons, changer
          votre point ou jour de retrait ou encore annuler
          votre commande
        </p>
      </div>

      <div
        v-else-if="isHistoricalOrder && order.billUrl"
        class="panel-section--transparent">
        <potager-button
          :is-loading="$wait.is('GET_ORDER_BILL')"
          full-width
          theme="stroke"
          @onClick="downloadBill">
          Télécharger la facture
        </potager-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION, EMPTY_BASKET_ACTION } from 'Stores/types/basketActionsTypes';

import PotagerButton from 'UI/PotagerButton';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerPicture from 'UI/PotagerPicture';

import Panel from 'Components/panel/Panel';
import OrderIndication from 'Components/orderIndication/OrderIndication';
import AnnouncementCard from 'Components/announcementCard/AnnouncementCard';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import CardProduct from 'Components/cards/CardProduct';
import PaymentOrderAction from 'Components/paymentMethods/PaymentOrderAction';

import AccountBreadcrumbOrders from 'Pages/account/orders/components/AccountOrdersBreadcrumb';
import AccountOrdersReceiptDetail from 'Pages/account/orders/components/AccountOrdersReceiptDetail';
import AccountOrdersPaymentSummary from 'Pages/account/orders/components/AccountOrdersPaymentSummary';

import MetaInfosService from 'Classes/services/MetaInfoService';
import {
  ABONNEMENT,
  ONESHOT,
  VALIDATED,
  PAYED,
} from 'Classes/OrderStatus';
import { downloadFile } from 'Classes/utils/FileDownloadUtils';
import { Context } from 'potagerlogic/dist/Enums/Context';
import { getOrderResumableDate } from 'Classes/utils/OrderUtils';

import OrderMixin from 'Mixins/OrderMixin';
import ShadowBasketMixin from 'Mixins/ShadowBasketMixin';

import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import AccountOrdersSubstituteSection from 'Pages/account/orders/components/AccountOrdersSubstituteSection.vue';

export default {
  mixins: [
    OrderMixin,
    ShadowBasketMixin,
  ],

  components: {
    AccountOrdersSubstituteSection,
    PotagerButton,
    PotagerInfo,
    PotagerPicture,
    Panel,
    AccountBreadcrumbOrders,
    OrderIndication,
    AnnouncementCard,
    DlpResume,
    CardProduct,
    PaymentOrderAction,
    AccountOrdersReceiptDetail,
    AccountOrdersPaymentSummary,
  },

  data: () => ({
    EMPTY_BASKET_ACTION,
    UPDATE_DELIVERY_POINT_BASKET_ACTION,
    ABONNEMENT,
    VALIDATED,
    PAYED,
    ONESHOT,
    Context,
    order: null,
  }),

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    isTemporary() {
      return false;
    },
    currentOrder() {
      if (this.isTemporary) {
        return this.getSubscription.temporaryOrder;
      }
      return this.$store.getters['user/getOrderById'](this.$potagerRoute.params.orderId);
    },
    isHistoricalOrder() {
      return this.currentOrder?.isHistorical;
    },
    backRoute() {
      return this.$potagerRoute.query.back
        ? JSON.parse(decodeURIComponent(this.$potagerRoute.query.back))
        : {
          name: 'mon-compte_orders',
          params: { status: this.isHistoricalOrder ? 'historique' : 'en-cours' }
        };
    },
    getBoxesGrouped() {
      if (!this.order) return null;

      let { boxes } = this.order;
      const groupBoxes = groupBy(boxes, 'id');

      // Add Actuals boxes
      boxes = boxes.map((b) => {
        const box = { ...b };
        box.nbItems = groupBoxes[box.id].length;
        return box;
      });

      boxes = uniqBy(boxes, 'id');

      // Formule first
      boxes.sort((a, b) => {
        if (a.isTypeFormule && !b.isTypeFormule) return -1;
        if (!a.isTypeFormule && b.isTypeFormule) return 1;
        return 0;
      });

      return boxes;
    },
    title() {
      if (this.isTemporary) return 'Commande en attente';
      return this.order ? `Commande n°${this.order.number ? this.order.number : this.order.id}` : null;
    },
    wantMoreText() {
      return `Vous pouvez compléter votre commande en ajoutant d'autres éléments <b>avant ce ${getOrderResumableDate(this.order)}.</b>`;
    },
  },

  watch: {
    currentOrder: {
      handler(val) {
        if (val) this.order = val;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('session', [
      UPDATE_REQUEST_URL_ACTION,
    ]),
    onResume(order) {
      this.resumeOrder(order.id)
        .then(({
          orderId,
          response
        }) => {
          this.resumeOrderSimulateResponse(orderId, response);
        });
    },
    downloadBill() {
      this.$wait.start('GET_ORDER_BILL');
      this.$api.user.getOrderBill(this.order.id)
        .then((res) => {
          const { data } = res;
          downloadFile(data, `Facture-WEB-${this.order.id}.pdf`);
        })
        .finally(() => {
          this.$wait.end('GET_ORDER_BILL');
        });
    },
    tryToDeleteProductFromOrder(box, order) {
      const remainingProducts = order.boxes.filter((b) => b.id !== box.id);

      if (!remainingProducts.length) {
        this.deleteOrder(order);
      } else {
        this.deleteProductFromOrder(box, order);
      }
    },
  },

  mounted() {
    this.UPDATE_REQUEST_URL_ACTION();

    if (this.isTemporary) {
      if (!this.getSubscription.temporaryOrder) {
        this.$router.push({ name: 'mon-compte_orders' })
          .catch(() => {
          });
      }
    } else if (this.isHistoricalOrder) {
      this.$api.order.getOneById(this.$potagerRoute.params.orderId)
        .then((resp) => {
          this.order = resp.data.data;
        });
    }
  },

  head() {
    return MetaInfosService.generate({
      title: this.title,
    });
  },
};
</script>
