<template>
  <div
    :class="[
      'payment',
      `payment--${context}`
    ]">
    <potager-form
      id="stripe-payment-form"
      @onSubmit="submit">
      <transition name="fade">
        <div
          v-if="(!isFormReady || isStripeLoading) && !isDisabled"
          class="payment__loader flex flex-col">
          <icon-ptcy-loader-spinner />

          <p
            v-if="isFormReady"
            class="text-xs text-center p-4">
            <b>L'ajout de votre carte est en cours de traitement.</b>
            <br>
            Vous allez bientôt être redirigé.
            <br>
            Une validation 3DSecure peut vous être demandée. Merci de bien vouloir la valider.
            <br>
            <br>
            Merci de ne pas fermer cette page.
          </p>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-if="isDisabled"
          class="payment__disabled" />
      </transition>

      <potager-row>
        <potager-col>
          <label class="payment__input-wrapper">
            <span
              :class="{
                'potager-input__label potager-input__label--external': true,
                'potager-input__label--invalid': status.cardNumber.error,
              }">
              Numéro de la carte
            </span>

            <div
              ref="cardNumber"
              :class="{
                'payment__input h-full': true,
                'payment__input--invalid': status.cardNumber.error,
              }" />
          </label>

          <potager-info
            v-if="status.cardNumber.error"
            size="small"
            status="warning">
            <template #icon>
              <icon-road-sign-warning />
            </template>
            {{ status.cardNumber.error.message }}
          </potager-info>
        </potager-col>

        <potager-col>
          <potager-input
            ref="cardName"
            v-model="cardName"
            :constraints="[{ constraint: 'required', field: 'cardName' }]"
            constraints-validation="onBlur"
            external-label
            label="Titulaire de la carte"
            name="cardName"
            placeholder="Nom Prénom" />
        </potager-col>

        <potager-col :col="(isTunnel || isModal) && !$mq.bp768 ? 6 : 12">
          <label class="payment__input-wrapper">
            <span
              :class="{
                'potager-input__label potager-input__label--external': true,
                'potager-input__label--invalid': status.cardExpiry.error,
              }">
              Date d'expiration
            </span>

            <div
              ref="cardExpiry"
              :class="{
                'payment__input': true,
                'payment__input--invalid': status.cardExpiry.error,
              }" />
          </label>

          <potager-info
            v-if="status.cardExpiry.error"
            size="small"
            status="warning">
            <template #icon>
              <icon-road-sign-warning />
            </template>
            {{ status.cardExpiry.error.message }}
          </potager-info>
        </potager-col>

        <potager-col :col="(isTunnel || isModal) && !$mq.bp768 ? 6 : 12">
          <label class="payment__input-wrapper">
            <span
              :class="{
                'potager-input__label potager-input__label--external': true,
                'potager-input__label--invalid': status.cardCvc.error,
              }">
              Code de vérification
            </span>

            <div
              ref="cardCvc"
              :class="{
                'payment__input': true,
                'payment__input--invalid': status.cardCvc.error,
              }" />
          </label>

          <potager-info
            v-if="status.cardCvc.error"
            size="small"
            status="warning">
            <template #icon>
              <icon-road-sign-warning />
            </template>
            {{ status.cardCvc.error.message }}
          </potager-info>
        </potager-col>
      </potager-row>

      <slot name="infos" />

      <div
        v-if="!hideSubmit"
        class="payment__submit-wrapper">
        <potager-button
          :full-width="!isModal || $mq.bp768"
          :is-loading="isStripeLoading"
          class="payment__submit min-w-40"
          theme="go-green"
          type="submit">
          Ajouter
        </potager-button>
      </div>

      <potager-info
        v-if="legendAuthorization"
        class="payment__legend"
        size="small">
        <template #icon>
          <icon-information-circle />
        </template>
        Demande d'autorisation de paiement entre 2 et 4 jours avant la livraison,
        prélèvement la veille de la livraison.
        <a
          href="https://potagercity.force.com/serviceclient/s/article/J-aperçois-une-ligne-sur-le-relevé-de-mon-compte-bancaire-quelques-jours-avant-la-livraison-Pourquoi"
          target="_blank">
          Plus d'infos</a>
      </potager-info>

      <potager-info
        v-if="legendCgv"
        class="payment__legend"
        size="small">
        <template #icon>
          <icon-information-circle />
        </template>
        En validant cette étape, vous acceptez l’intégralité de
        <potager-link
          :to="{ name: 'cgv' }"
          target="_blank">
          nos conditions générales de vente
        </potager-link>
        et notre politique de gestion de vos informations personnelles
      </potager-info>
    </potager-form>

    <div
      v-if="paymentMethod.alternativeIcons && paymentMethod.alternativeIcons.length > 0 && !hideIcons"
      :class="[
        { 'pb-0': isModal },
        'p-5 text-xl.5 flex justify-center items-center mt-2 bg-transparent'
      ]">
      <component
        :is="icon"
        v-for="(icon, index) in paymentMethod.alternativeIcons"
        :key="`icon-${index}`"
        :color="paymentMethod.colors.primary"
        class="mx-1" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'Classes/utils/BasicUtils';
import { PAYMENT_METHODS_INTEGRATIONS } from 'Classes/payment-methods/Constants';
import { getPMByName } from 'Classes/payment-methods';

import StripeMixin, { STRIPE_PROCESSING_LOADER } from 'Mixins/payments/StripeMixin';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';
import IconInformationCircle from 'Icons/regular/IconInformationCircle';

import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerForm from 'UI/Form/PotagerForm';

import { fonts, classes, style } from 'Components/stripe/StripeStyles';
import COLORS from 'potagerlogic/dist/Enums/Colors';

const { white } = COLORS;

export default {
  mixins: [
    StripeMixin,
  ],

  components: {
    IconPtcyLoaderSpinner,
    IconRoadSignWarning,
    IconInformationCircle,
    PotagerButton,
    PotagerInput,
    PotagerRow,
    PotagerCol,
    PotagerInfo,
    PotagerForm,
  },

  emits: ['canSubmit', 'preCheckError'],

  data: () => ({
    colors: { white },
    cardNumber: null,
    cardName: '',
    cardExpiry: null,
    cardCvc: null,
    status: {
      cardNumber: {},
      cardExpiry: {},
      cardCvc: {},
    },
    ready: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    },
    STRIPE_PROCESSING_LOADER,
    isFilling: false,
  }),

  props: {
    context: {
      type: String,
      required: false,
      default: 'account',
    },
    hideIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    legendAuthorization: {
      type: Boolean,
      required: false,
      default: false,
    },
    legendCgv: {
      type: Boolean,
      required: false,
      default: false,
    },
    setPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    isTunnel() {
      return this.context === 'tunnel';
    },
    isModal() {
      return this.context === 'modal';
    },
    paymentMethod() {
      return getPMByName(PAYMENT_METHODS_INTEGRATIONS.STRIPE.name);
    },
    isFormReady() {
      const {
        cardNumber,
        cardExpiry,
        cardCvc
      } = this.ready;
      return Boolean(cardNumber && cardExpiry && cardCvc && this.clientSecret && this.stripe);
    },
    canSubmit() {
      const {
        cardNumber,
        cardExpiry,
        cardCvc
      } = this.status;
      return !!(cardNumber.complete && this.cardName && cardExpiry.complete && cardCvc.complete);
    },
  },

  watch: {
    canSubmit: {
      handler(val, oldVal) {
        if (val !== oldVal) this.$emit('canSubmit', val);
      },
      immediate: true,
    },
    status: {
      handler(val) {
        if (!this.isFilling && Object.keys(val)
          .map((e) => val[e].empty === false)
          .filter((e) => e).length) {
          this.isFilling = true;
        }
      },
      deep: true,
    },
    cardName(val) {
      if (!this.isFilling && val) this.isFilling = true;
    },
    isFilling(val) {
      if (val) this.tracking('fill');
    },
    getPaymentGateway: {
      handler(val) {
        if (val !== PAYMENT_METHODS_INTEGRATIONS.PAYGREEN) {
          this.initStripe()
            .then(() => {
              this.setStripeSecretClient();
              this.initForm();
            });
        } else {
          this.removeListeners();
        }
      },
      immediate: true,
    },
  },

  methods: {
    // Création du formulaire Stripe
    // - cardNumber
    // - cardExpiry
    // - cardCvc
    // Le cardName est géré du côté de Potager
    initForm() {
      const elements = this.stripe.elements({ fonts });

      this.cardNumber = elements.create('cardNumber', {
        style,
        classes
      });
      this.cardExpiry = elements.create('cardExpiry', {
        style,
        classes
      });
      this.cardCvc = elements.create('cardCvc', {
        style,
        classes
      });

      const {
        cardNumber,
        cardExpiry,
        cardCvc
      } = this.$refs;

      if (cardNumber) this.cardNumber.mount(cardNumber);
      if (cardExpiry) this.cardExpiry.mount(cardExpiry);
      if (cardCvc) this.cardCvc.mount(cardCvc);

      if (cardNumber && cardExpiry && cardCvc) this.addListeners();
    },
    // Pose les écouteur sur le formulaire Stripe pour gérer leurs status (ready, errors)
    addListeners() {
      this.cardNumber.addEventListener('change', this.setErrors);
      this.cardNumber.addEventListener('ready', this.setReady);
      this.cardNumber.addEventListener('blur', this.setEmpty);
      this.cardExpiry.addEventListener('change', this.setErrors);
      this.cardExpiry.addEventListener('ready', this.setReady);
      this.cardExpiry.addEventListener('blur', this.setEmpty);
      this.cardCvc.addEventListener('change', this.setErrors);
      this.cardCvc.addEventListener('ready', this.setReady);
      this.cardCvc.addEventListener('blur', this.setEmpty);
    },
    // Suppression des écouteurs lors de la déstruction du composant
    removeListeners() {
      if (this.cardNumber) {
        this.cardNumber.removeEventListener('change', this.setErrors);
        this.cardNumber.removeEventListener('ready', this.setReady);
        this.cardNumber.removeEventListener('blur', this.setEmpty);
      }
      if (this.cardExpiry) {
        this.cardExpiry.removeEventListener('change', this.setErrors);
        this.cardExpiry.removeEventListener('ready', this.setReady);
        this.cardExpiry.addEventListener('blur', this.setEmpty);
      }
      if (this.cardCvc) {
        this.cardCvc.removeEventListener('change', this.setErrors);
        this.cardCvc.removeEventListener('ready', this.setReady);
        this.cardCvc.addEventListener('blur', this.setEmpty);
      }
    },
    // Gestion des erreurs d'un champ Stripe
    setErrors(event) {
      if (event) this.status[event.elementType] = event;
    },
    // Gestion du status "ready" d'un champ Stripe
    setReady(event) {
      if (event) this.ready[event.elementType] = true;
    },
    // Gestion du status "ready" d'un champ Stripe
    setEmpty(event) {
      const elt = event.elementType;
      const status = this.status[elt];

      if (isEmpty(status) || status.empty) {
        this.status[elt] = {
          error: { message: 'Cette valeur ne doit pas être vide.' },
          empty: true
        };
        this.$refs[elt].classList.add('payment__input--invalid');
      }
    },
    preCheckForm() {
      // check error on regular input
      this.$refs.cardName.showError();
      // check empty stripe input
      Object.keys(this.status)
        .forEach((key) => {
          this.setEmpty({ elementType: key });
        });
      // scroll to the first error of the form
      this.$emit('preCheckError');
    },
    submit() {
      if (this.$wait.is(STRIPE_PROCESSING_LOADER)) return;

      if (this.canSubmit) {
        this.stripePayment();
      } else {
        this.preCheckForm();
      }
    },
    tracking(eventValue) {
      try {
        this.$gtm.trackEvent({
          event: 'FillForm',
          eventLabel: 'FormBluecard',
          eventValue,
        });
      } catch (e) { /* Do Nothing */
      }
    },
  },

  beforeUnmount() {
    this.removeListeners();
  },

};
</script>

<style lang="scss">
.paygreen-pan-frame,
.paygreen-cvv-frame,
.paygreen-exp-frame {
  &,
  > iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
