<template>
  <div
    v-if="isVisible"
    class="bg-go-green">
    <potager-alert
      v-if="isBadIE"
      id="IE-accepted"
      theme="zest">
      Notre site n’étant plus compatible avec Internet Explorer (<a
        href="https://www.microsoft.com/fr-fr/microsoft-365/windows/end-of-ie-support"
        target="_blank">
        Microsoft ayant annoncé la fin du maintien du service
      </a>), nous vous conseillons donc d’utiliser un autre navigateur.
    </potager-alert>

    <potager-alert
      v-if="isTestModeActivated"
      id="test-mode"
      :redirect="{ name: 'test-mode' }"
      theme="zest">
      Mode test <b>activé</b> !
    </potager-alert>

    <potager-alert
      :id="showedAlert?.id"
      :key="showedAlert?.id"
      :clickable="!!showedAlert?.action"
      :redirect="showedAlert?.link"
      :theme="showedAlert?.colorType ? showedAlert.colorType.toLowerCase() : undefined"
      :transition="alerts?.length > 0 ? 'fade' : 'collapse'"
      closable
      @onClick="showedAlert?.action()"
      @onDismiss="dismissedAlerts.push($event)">
      <span v-html="showedAlert?.message" />
    </potager-alert>

    <potager-alert
      v-if="getNeededActionOrders?.length"
      id="order-needed-action"
      :redirect="getNeededActionOrdersLink"
      closable
      theme="zest">
      ACTION REQUISE - Votre commande n°{{ getNeededActionOrders[0].number }} n’a pas encore été validée.
      <template #action>
        <potager-button
          :to="getNeededActionOrdersLink"
          size="small"
          theme="white">
          Voir ma commande
        </potager-button>
      </template>
    </potager-alert>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import { mapActions, mapGetters } from 'vuex';
import { SET_HAS_SHOWN_ALERTS_ACTION } from 'Stores/types/appActionTypes';

import { IEDetectVersion } from 'Classes/utils/BrowserDetectUtils';

import SeasonalCouponMixin from 'Mixins/SeasonalCouponMixin';

import PotagerAlert from 'UI/PotagerAlert';
import PotagerButton from 'UI/PotagerButton';

export default {
  mixins: [
    SeasonalCouponMixin,
  ],

  components: {
    PotagerAlert,
    PotagerButton,
  },

  data: () => ({
    isBadIE: IEDetectVersion() <= 11,
    showedAlertIndex: 0,
    dismissedAlerts: [],
    interval: null,
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
      'isTestModeActivated',
    ]),
    ...mapGetters('user', [
      'getNeededActionOrders',
    ]),
    ...mapGetters('app', [
      'getAlerts',
    ]),
    isVisible() {
      const hasAlerts = this.showedAlert || this.isBadIE || this.isTestModeActivated || this.getNeededActionOrders?.length;
      return this.$route?.meta?.header !== false && !!hasAlerts;
    },
    getNeededActionOrdersLink() {
      return this.getNeededActionOrders.length ? {
        name: 'mon-compte_orders_detail',
        params: {
          orderId: this.getNeededActionOrders[0].id,
        },
      } : null;
    },
    showPromotedAlert() {
      // hide promoted banner on "static content" pages
      // this basically keep it on homepage, tunnel & boutique
      const allMatchNames = this.$route.matched.map((match) => match.name);
      const excludedPageTypes = ['static', 'content'];
      const functionalConditions = !!this.promotedCoupon && this.isFirstOrder;
      const pagesCondition = allMatchNames.length > 0 && !excludedPageTypes.some((layout) => allMatchNames.includes(layout));
      return functionalConditions && pagesCondition;
    },
    alerts() {
      const alerts = this.getAlerts;

      if (this.showPromotedAlert && !alerts.some((alert) => alert.id === this.promotedCoupon.id)) {
        alerts.unshift({
          id: this.promotedCoupon.id,
          message: this.promotedCoupon.promotedTitle,
          action: this.showCouponPolicy,
          colorType: 'go-green',
        });
      }

      return alerts
        // filter out dismissed alerts
        .filter((alert) => !this.dismissedAlerts.includes(alert.id))
        // filter out alerts that have been dismissed by the user
        .filter((alert) => !Cookies.get(`alert-${alert.id}`))
        // filter out promoted alert if needed
        .filter((alert) => !this.promotedCoupon || !(alert.id === this.promotedCoupon?.id && !this.showPromotedAlert));
    },
    showedAlert() {
      return this.alerts[this.showedAlertIndex];
    },
  },

  methods: {
    ...mapActions('app', {
      SET_HAS_SHOWN_ALERTS_ACTION
    }),
    setInterval() {
      // alternate between alerts each 10 seconds
      this.interval = setInterval(() => {
        this.showedAlertIndex = (this.showedAlertIndex + 1) % this.alerts.length;
      }, 10000);
    },
  },

  watch: {
    '$route.name'() {
      this.showedAlertIndex = 0; // Else, we have an issue when we route changes from a page that allows promoted alert to a page that doesn't
    },
    alerts: {
      handler(next, prev) {
        // if we have more than one alert, we need to alternate between them
        // if we have only one alert, we need to stop the interval
        if ((!prev || prev?.length <= 1) && next?.length > 1) {
          this.setInterval();
        } else if (prev?.length > 1 && next?.length <= 1) {
          clearInterval(this.interval);
        }
      },
      immediate: true,
      deep: true,
    },
    isVisible: {
      handler(isVisible) {
        this.SET_HAS_SHOWN_ALERTS_ACTION(isVisible);
      },
      immediate: true,
    },
  },
};
</script>
