<template>
  <div
    v-if="isVisible"
    class="panel-section">
    <p class="panel-section__subtitle">
      Récapitulatif de paiement
    </p>

    <potager-info
      v-for="(payment, index) in getFilteredPayments"
      :key="index"
      class="panel-section__line"
      font-style="normal">
      <template #icon>
        <component :is="getIcon(payment)" />
      </template>

      Débit
      <template v-if="getGatewayMsg(payment)">
        {{ getGatewayMsg(payment) }}
      </template>
      de&nbsp;<b>{{ formatPrice(payment.amount) }}</b>

      le&nbsp;{{ tmsToFormat(order.paymentDate, 'dd/MM') }}
      <span class="whitespace-nowrap">
        {{ getGatewayLegend(payment) }}
      </span>
    </potager-info>
  </div>

  <div
    v-else-if="showSecondaryPaymentMethodsLegend"
    class="panel-section">
    <payment-methods-selectable-item class="bg-white-smoke">
      <template #type>
        {{ getSecondaryPaymentMethod.label }}
      </template>
      <template #icon>
        <component
          :is="icon"
          v-for="(icon, index) in getSecondaryPaymentMethod.icons"
          :key="`${getSecondaryPaymentMethod.name}-${index}`"
          :class="`text-${getSecondaryPaymentMethod.name}`" />
      </template>
      <template #legend>
        {{ getSecondaryPaymentMethod.helpers.subscriptionOrder }}
      </template>
    </payment-methods-selectable-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice } from 'Classes/utils/PricesUtils';

import { VALIDATED, PAYED, CAPTURED, ABONNEMENT, DELIVERED, PREPARATION } from 'Classes/OrderStatus';
import { getPMByName, getPaymentMethod, PRIMARY_PAYMENT_METHODS } from 'Classes/payment-methods';
import { PAYMENT_METHODS_TYPES } from 'Classes/payment-methods/Constants';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

import PotagerInfo from 'UI/PotagerInfo';
import PaymentMethodsSelectableItem from 'Components/paymentMethods/PaymentMethodsSelectableItem';
import OrderMixin from 'Mixins/OrderMixin';

export default {
  components: {
    PotagerInfo,
    PaymentMethodsSelectableItem,
  },

  mixins: [
    OrderMixin,
  ],

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getCardById',
      'getPaygreenCardById',
      'getPaymentAccountUsableForSubscription',
    ]),
    isVisible() {
      return [VALIDATED, PAYED, DELIVERED, PREPARATION].includes(this.order.frontStatus)
        && this.getFilteredPayments?.length;
    },
    showSecondaryPaymentMethodsLegend() {
      return [VALIDATED].includes(this.order.frontStatus)
        && this.order.canal === ABONNEMENT
        && this.getPaymentAccountUsableForSubscription?.usableForSubscription;
    },
    getFilteredPayments() {
      return this.order.paymentAttempt?.payments?.filter((e) => [CAPTURED].includes(e.status));
    },
    getSecondaryPaymentMethod() {
      if (this.showSecondaryPaymentMethodsLegend) {
        return getPMByName(this.getPaymentAccountUsableForSubscription.name);
      }
      return null;
    },
  },

  methods: {
    formatPrice,
    tmsToFormat,
    getGatewayMsg(card) {
      const PM = getPaymentMethod(card);
      return PM?.abbr || PM?.label;
    },
    getIcon(card) {
      return getPaymentMethod(card)?.defaultIcon || PRIMARY_PAYMENT_METHODS[0].defaultIcon;
    },
    getGatewayLegend(payment) {
      const card = this.getCardById(payment.blueCard);
      const paymentMethod = getPaymentMethod(card);

      if (PAYMENT_METHODS_TYPES.BANK_CARD === paymentMethod?.type) {
        return card ? `sur la carte bancaire ${card.number.replace('XXXXXXXXXXXX', '•••• •••• •••• ')}` : 'sur carte bancaire';
      }
      if (PAYMENT_METHODS_TYPES.RESTAURANT_CARD === paymentMethod?.type) {
        return card ? `sur la carte titre-restaurant ${card.number.replace('XXXXXXXXXXXX', '•••• •••• •••• ')}` : 'sur carte titre-restaurant';
      }
      if (card?.number) {
        return `sur la carte ${card.number.replace('XXXXXXXXXXXX', '•••• •••• •••• ')}`;
      }

      return null;
    },
  },

};
</script>
