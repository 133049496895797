import { PAYGREEN_PAYMENT_METHODS } from 'Classes/payment-methods';

export default function (to, from, next) {
  const { name } = to.params;
  if (PAYGREEN_PAYMENT_METHODS
    .find((pm) => pm.name === name)) {
    next();
  } else {
    console.info('paygreenPlatformGuard > Redirecting to mon-compte_payment-methods');
    next({ name: 'mon-compte_payment-methods' });
  }
}
