<template>
  <flying-page>
    <div class="product-detail">
      <product-detail-tags :box="box" />

      <transition name="fade-abs">
        <skeleton
          v-if="box.loading"
          context="product" />

        <div v-else>
          <potager-picture
            :src="box.image"
            :title="box.name"
            background
            class="product-detail__poster" />

          <div class="product-detail__content">
            <product-detail-heading
              :box="box" />

            <product-detail-products-list
              v-if="box.products.length"
              :box="box"
              :disable-link="true" />
          </div>
        </div>
      </transition>
    </div>

    <template #footer>
      <product-tools :box="box">
        <template #action>
          <potager-button
            :is-loading="$wait.is([
              `${REPLACE_SUBSCRIPTION_PRODUCT}_${box.id}`,
              CONFIRM_SHADOW_BASKET_ACTION
            ])"
            theme="go-green"
            @onClick="replace">
            Remplacer
          </potager-button>
        </template>
      </product-tools>
    </template>
  </flying-page>
</template>

<script>
import { mapGetters } from 'vuex';

import FlyingPageMixin from 'Mixins/FlyingPageMixin';
import ShadowBasketMixin from 'Mixins/ShadowBasketMixin';

import ProductDetailTags from 'Pages/flyingPages/ProductDetailComponents/ProductDetailTags';
import ProductDetailHeading from 'Pages/flyingPages/ProductDetailComponents/ProductDetailHeading';
import ProductDetailProductsList from 'Pages/flyingPages/ProductDetailComponents/ProductDetailProductsList';

import ProductTools from 'Components/productTools/ProductTools';

import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { isEmpty } from 'Classes/utils/BasicUtils';

import { REPLACE_SUBSCRIPTION_PRODUCT, CONFIRM_SHADOW_BASKET_ACTION } from 'Stores/types/shadowBasketActionsTypes';

export default {

  mixins: [
    FlyingPageMixin,
    ShadowBasketMixin,
  ],

  components: {
    ProductDetailTags,
    ProductDetailHeading,
    ProductDetailProductsList,
    ProductTools,
    PotagerButton,
    PotagerPicture,
  },

  data: () => ({
    REPLACE_SUBSCRIPTION_PRODUCT,
    CONFIRM_SHADOW_BASKET_ACTION,
    box: { loading: true },
  }),

  computed: {
    ...mapGetters('user', [
      'getNextEditableSubscriptionOrder',
    ]),
    idProduit() {
      return parseInt(this.$potagerRoute.params.id, 10);
    },
    idRegion() {
      return parseInt(this.$potagerRoute.params.idRegion, 10);
    },
  },

  methods: {
    getBox() {
      this.$api.box.findOneWithHistoryByBasket(this.idRegion, this.idProduit, 'basket')
        .then(({ data }) => {
          this.box = data;
        })
        .catch(() => {
          this.close();
        });
    },
    close() {
      this.$events.emit('flyingPage:close');
    },
    replace() {
      if (this.idProduit && this.getNextEditableOrder) {
        this.replaceSubscriptionProduct({ id: this.idProduit }, this.getNextEditableOrder);
      } else {
        this.$notify({
          type: 'error',
          title: 'Une erreur est survenue',
          text: 'Impossible de remplacer votre panier pour le moment',
        });
      }
    },
  },

  mounted() {
    this.getBox();
  },

  head() {
    let metaInfo = {};

    if (this.box.id) {
      metaInfo = {
        title: `${this.box.name} (${this.box.region.shortName})`,
        description: this.box.descriptionOfWeek,
        image: this.box.image ? this.box.image.url : null,
      };
    }

    if (!isEmpty(metaInfo)) {
      return MetaInfosService.generate(metaInfo);
    }

    return metaInfo;
  },

};
</script>
