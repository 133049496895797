import { EDIT_EDENRED_SETTINGS, LOGOUT_EDENRED, GET_EDENRED_LOGIN_URL } from 'Stores/types/userActionsTypes';
import IconPtcyEdenred from 'Icons/bold/IconPtcyEdenred';
import IconPtcyEdenredColor from 'Icons/color/IconPtcyEdenred';

import ModalPedagogyTicketResto from 'Components/modals/ModalPedagogyTicketResto';

import { inAppWebView } from 'Classes/ConfigurationManager';
import { getResolvedUrl } from 'Classes/utils/RouteUtils';

import PAYMENT_METHODS_CONSTANTS from 'Classes/payment-methods/Constants';

import { openModal } from 'Plugins/potagerModals';
import { storeDispatch } from 'Services/storeServices';

export const {
  type,
  integration,
  colors,
} = PAYMENT_METHODS_CONSTANTS.EDENRED;

export default {
  name: PAYMENT_METHODS_CONSTANTS.EDENRED.name,
  type,
  integration,
  label: 'carte Ticket Restaurant® Edenred',
  title: 'Carte Edenred',
  readmoreLink: 'https://potagercity.force.com/serviceclient/s/article/Comment-utiliser-ma-carte-edenred-comme-moyen-de-paiement',

  route: { name: 'mon-compte_payment-methods_edenred' },
  sav: 'https://potagercity.force.com/serviceclient/s/article/Comment-utiliser-ma-carte-edenred-comme-moyen-de-paiement',

  helpers: {
    login: `
      En cliquant sur le bouton "Connecter", vous allez être redirigé vers le site MyEdenred.<br>
      Saisissez votre email et votre mot de passe, puis validez les étapes suivantes.<br>
      Une fois toutes ces étapes validées, vous serez connecté à votre carte Edenred et pourrez commencer à l'utiliser.
    `,
    logout: `
      Votre carte Edenred sera déconnectée et ne sera plus débitée.
      Vous devrez vous reconnecter pour l'utiliser à nouveau.
    `,
    setDefault: `
      J'utilise automatiquement le solde de ma carte Edenred pour toutes mes commandes à venir.
      Pour chaque commande en mode Abonnement, j'autorise Potager City à prélever le
      solde au moment de la génération de la commande (la veille de la livraison).
    `,
    subscriptionOrder: `
      Au moment du paiement de votre commande, nous prélèverons en priorité sur votre carte Edenred.
      Si le solde est insuffisant ou le plafond dépassé, nous prélèverons la différence sur votre carte bancaire.
      Dans cette éventualité, nous avons effectué une autorisation sur votre carte bancaire du montant total de
      votre commande.
    `,
  },

  defaultIcon: IconPtcyEdenred,
  icons: [IconPtcyEdenred],
  alternativeIcons: [IconPtcyEdenredColor],

  colors,

  loaders: {
    login: GET_EDENRED_LOGIN_URL,
    logout: LOGOUT_EDENRED,
    editSettings: EDIT_EDENRED_SETTINGS,
  },

  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalPedagogyTicketResto,
        {
          name: PAYMENT_METHODS_CONSTANTS.EDENRED.name,
          cardIconComponent: IconPtcyEdenred,
          cardIconFullComponent: IconPtcyEdenredColor,
          cardIconColor: colors.primary,
        },
        undefined,
        (e) => {
          if (typeof onClose === 'function') onClose();
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
        });
    },
    redirect: (routeName = 'mon-compte_payment-methods_edenred') => {
      const redirectUrl = `${getResolvedUrl(routeName)}?from=edenred${inAppWebView() ? `&utm_source=app&token=${localStorage.getItem('token')}` : ''}`;
      storeDispatch(`user/${GET_EDENRED_LOGIN_URL}`, redirectUrl)
        .then(({ data }) => {
          document.location.href = data;
        });
    },
    logout: () => {
      storeDispatch(`user/${LOGOUT_EDENRED}`);
    },
    setDefault: (value) => {
      storeDispatch(`user/${EDIT_EDENRED_SETTINGS}`, {
        usableForSubscription: !!value,
      });
    },
  },
};
