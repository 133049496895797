import { getUrlParameter } from 'Classes/utils/RouteUtils';

export const shortcuts = [
  {
    label: '1',
    url: 'https://api.stagging.potagercity.tk',
  },
  {
    label: '2',
    url: 'https://api.stagging.2.potagercity.tk',
  },
  {
    label: '3',
    url: 'https://api.stagging.3.potagercity.tk',
  },
  {
    label: '4',
    url: 'https://api.stagging.4.potagercity.tk',
  },
  {
    label: 'Preprod',
    url: 'https://preprodapi.potagercity.fr',
  },
  {
    label: 'Prod',
    url: 'https://api.potagercity.fr',
  },
];

export const setApiUrl = () => {
  const urlValue = getUrlParameter('apiswitcher')
    && shortcuts.filter((e) => e.label === getUrlParameter('apiswitcher'))?.[0]?.url;
  const storageValue = localStorage.getItem('api-switcher');
  const value = urlValue || storageValue;

  if (value && value !== storageValue) {
    localStorage.setItem('api-switcher', value);
  }
};
