import { mapGetters } from 'vuex';

import { ORDER_ALC_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { tmsToFormat } from 'Classes/utils/DatesUtils';

import ModalList from 'Components/modals/ModalList';

export default {

  computed: {
    ...mapGetters('app', [
      'getPromotedCoupons',
    ]),
    ...mapGetters('session', [
      'getRedirectedPageByName',
      'isLoggedIn',
    ]),
    ...mapGetters('tunnel', [
      'getTypeValue',
    ]),
    ...mapGetters('user', [
      'isFirstOrder',
    ]),
    promotedCoupon() {
      return this.getPromotedCoupon();
    },
  },

  methods: {
    getPromotedCoupon() {
      if (this.getPromotedCoupons && this.getPromotedCoupons.length > 0) {
        const inALCContext = this.getTypeValue(ORDER_TYPE) === ORDER_ALC_TYPE && this.$route.name.includes('tunnel');
        const subscriptionCoupon = this.getPromotedCoupons.find((coupon) => coupon.couponUsableOnlySubscription === true);
        const nonSubscriptionCoupon = this.getPromotedCoupons.find((coupon) => coupon.couponUsableOnlySubscription === false);
        return inALCContext ? nonSubscriptionCoupon : subscriptionCoupon || this.getPromotedCoupons[0];
      }
      return null;
    },
    showCouponPolicy(type) {
      const promotedCoupon = this.getPromotedCoupon(type);

      if (promotedCoupon) {
        const couponEndDate = tmsToFormat(promotedCoupon.couponEndDate, 'dd/MM/yyyy');
        const hearth = ['🧡', '❤️', '💛', '💚', '💙', '💜', '🖤', '🤍', '🤎'];
        const btnRoute = { name: 'tunnel' };

        this.$modal.open(ModalList, {
          title: `<span class="font-normal">Offre${this.getPromotedCoupons.length > 1 ? 's' : ''} de</span> bienvenue`,
          items: this.getPromotedCoupons.map((coupon, index) => {
            let title = coupon.label;
            if (coupon.couponUsableOnlySubscription) {
              title = `Code ${this.getPromotedCoupons.length > 1 ? 'abonné ' : ''}: ${coupon.couponCode}`;
            } else if (coupon.couponUsableOnlySubscription === false) {
              title = `Code ${this.getPromotedCoupons.length > 1 ? 'non abonné ' : ''} : ${coupon.couponCode}`;
            }

            return {
              title,
              description: coupon.promotedDescription,
              icon: hearth[index],
            };
          }),
          btnLabel: btnRoute ? 'J\'en profite !' : 'Fermer',
          btnTheme: btnRoute ? 'go-green' : undefined,
          btnRoute,
          btnSuffix: `Offres non cumulables, réservées à la toute première commande. Fin de validité de l'offre : ${couponEndDate}`,
        });
      }
    },
  },
};
