<template>
  <transition name="fade">
    <potager-navigator
      v-if="subscriberReduction > 0 && !isOrderCanceledStatus(order)"
      :theme="isSubscriber ? 'beryl-green' : 'sandy-beach'"
      :to="noNavigate ? undefined : { name: 'basket_subscription' }">
      <span class="flex justify-between items-center text-left">
        <span class="mr-auto font-medium truncate">
          {{
            isSubscriber ? '⚡️&nbsp;Vos avantages abonné&nbsp;:&nbsp;' : '⚡️&nbsp;Économisez&nbsp;'
          }}<b>{{ formatPrice(subscriberReduction) }}</b> {{ isSubscriber ? 'économisés' : 'avec l’abonnement' }}
        </span>
      </span>
    </potager-navigator>
  </transition>
</template>

<script>
import PotagerNavigator from 'UI/PotagerNavigator';

import {
  getSubscriberReduction,
  getSubscriptionEnterpriseDiscount,
  formatPrice,
} from 'Classes/utils/PricesUtils';

import { isOrderCanceledStatus } from 'potagerlogic/dist/Utils/Order/OrderStatus';
import { SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';

export default {
  name: 'SubscriberBenefitsNavigator',
  methods: {
    isOrderCanceledStatus,
    formatPrice
  },

  components: {
    PotagerNavigator,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    noNavigate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isSubscriber() {
      return SubscriptionContexts.includes(this.order?.priceCategory);
    },
    subscriberReduction() {
      return getSubscriberReduction(this.order) - getSubscriptionEnterpriseDiscount(this.order);
    },
  },
};
</script>
